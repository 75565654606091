@import "helpers.module";

.Services {
  .service-boxes-container {
    display: grid;
    gap: 8rem;

    .IndividualServiceBox {
      display: grid;
      grid-template-columns: 1fr 1.2fr;
      gap: 5rem;

      &:nth-child(even) {
        grid-template-columns: 1.2fr 1fr;

        > .image-grid-item {
          grid-column: 2/3;
          grid-row: 1;
        }
      }

      @media (max-width: 950px) {
        gap: 3rem;
      }

      > .image-grid-item {
        display: grid;
        align-content: center;
        > div {
          > div {
            box-shadow: 0 0 50px 10px rgba(black, 0.2);
            background-color: colors(light-gray-bg);
            border-radius: 100000000rem;
            padding-top: 100%;
            position: relative;
            > div {
              position: absolute;
              @include setProps(left right top bottom, 3%);
              border-radius: 1000000rem;
              overflow: hidden;

              > img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      > .text-grid-item {
        display: grid;
        align-content: center;
        gap: 2rem;
        justify-items: start;

        > .top-subtitle {
          text-transform: uppercase;
          color: colors(light-blue);
          font-weight: bold;
          font-size: 1.1rem;
        }

        > .title {
          font-size: 2.7rem;
          font-weight: bold;
        }

        > .bottom-subtitle {
          font-size: 1.2rem;
          line-height: 1.5;
          color: colors(grayish-blue-text);
        }

        > .contact-us-button {
          border: 3px solid colors(dark-light-blue);
          color: colors(dark-light-blue);
          border-radius: 10000000rem;

          padding: 0.8rem 1rem;

          display: grid;
          grid-auto-flow: column;
          align-items: center;
          font-weight: bold;
          gap: 0.6rem;
          @include bounceOnHover;
          > .text {
            font-size: 1rem;
          }

          > .chevron {
            font-size: 1.5rem;
            line-height: 0.6;
          }
        }
      }

      @include respondToNarrowWidth {
        grid-template-columns: initial !important;
        gap: 2.5rem;
        > .image-grid-item {
          grid-column: initial !important;
          grid-row: initial !important;
          grid-template-columns: minmax(0, 23rem);
          justify-content: center;
        }

        > .text-grid-item {
          text-align: center;
          justify-items: center;
          max-width: 42rem;
          justify-self: center;
        }
      }
    }
  }
}
