@import "helpers.module";
@import "../../products-variables.module";

.Products > .content {
  > .MainContent {
    > .ProductItemsGrid {
      // $column-config: auto;

      $column-config: minmax(0, 330px);

      margin-left: -$product-grid-insets;
      margin-top: $product-grid-insets;
      display: grid;
      gap: $product-grid-insets;
      grid-template-columns: $column-config;
      justify-content: center;
      justify-items: stretch;
      align-items: start;

      @media (min-width: 550px) {
        // grid-template-columns: auto auto;
        grid-template-columns: repeat(2, $column-config);
      }

      @media (min-width: 850px) {
        // grid-template-columns: auto auto auto ;
        grid-template-columns: repeat(3, $column-config);
      }

      @media (min-width: $min-width-for-displaying-side-bar) {
        // grid-template-columns: auto auto;
        grid-template-columns: repeat(2, $column-config);
      }

      @media (min-width: 1250px) {
        // grid-template-columns: auto auto auto;
        grid-template-columns: repeat(3, $column-config);
      }

      @media (min-width: 1600px) {
        // grid-template-columns: auto auto auto auto;
        grid-template-columns: repeat(4, $column-config);
      }

      > .ProductOrCategoryItem {
        @mixin configureTransition($properties) {
          transition-property: $properties;
          transition-duration: 0.2s;
        }

        // max-width: 315px;

        padding: $product-grid-insets;
        position: relative;

        > .background-view {
          position: absolute;
          background-color: $product-item-info-background-color;
          box-shadow: 0px 0px 3.3rem 1rem rgba(colors(dark-blue), 0.9);
          border-radius: 0.8rem;
          left: $product-grid-insets * 2;
          bottom: 0;
          right: 0;
          top: $product-grid-insets * 2;
          @include configureTransition(top left);
        }

        @media (hover: hover) {
          &:hover {
            z-index: 1;
            > .background-view {
              top: 0;
              left: 0;
              bottom: -$product-grid-insets;
            }

            > .content-box {
              > .under-image-content {
                transform: translateX(-$product-grid-insets);
                > .text-box > .title {
                  @include applyBlueThemeTextGradient;
                }
              }
              > .image-box > .ProductItemImageView {
                transform: scale(1.1);
              }
            }
          }
        }

        > .content-box {
          position: relative;

          > .image-box {
            border-radius: 0.55rem;
            position: relative;
            overflow: hidden;

            // because of border radius issues in safari
            will-change: transform;

            > .ProductItemImageView {
              @include configureTransition(transform);
            }

            > .product-or-category {
              position: absolute;
              @include setProps(right top, 0.5rem);
              text-transform: uppercase;
              justify-self: start;
              font-weight: bold;
              font-size: 11.2px;
              padding: 0.265rem 0.4rem 0.2rem;
              border-radius: 0.3rem;
              background-color: colors(light-blue);
              box-shadow: 0 0 0.6rem 0.3rem rgba(black, 0.2);
            }
          }

          > .under-image-content {
            margin-left: $product-grid-insets * 2;
            margin-top: $product-grid-insets;
            @include configureTransition(transform);

            > .text-box {
              display: grid;
              gap: 0.5rem;
              justify-items: start;

              > .title {
                font-weight: bold;
                font-size: 14px;
                word-break: break-word;
                // for microsoft edge on windows
                word-wrap: break-word;
                @include setMaximumLines(2);
              }

              > .description {
                font-size: 14px;
                color: colors(grayish-blue-text);
                word-break: break-word;
                // for microsoft edge on windows
                word-wrap: break-word;
                @include setMaximumLines(2);
              }
            }
          }
        }
      }
    }
  }
}
