@import "helpers.module";

.LoadingButton {
  position: relative;
  > .LoadingIndicator {
    position: absolute;
    @include setProps(top left, 50%);
    transform: translate(-50%, -50%);
  }
}
