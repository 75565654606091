@import "helpers.module";

.ErrorMessageBox {
  font-size: 14px;
  line-height: 1;

  --highlight-color: #{colors(destructive-red)};

  color: var(--highlight-color);
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;

  svg {
    @include setProps(width height, 1.1em);
    fill: var(--highlight-color);
  }
}
