@import "helpers.module";

.EditProductItemView {
  > .container {
    margin-top: 3rem;
    display: grid;
    gap: 2rem;

    .ErrorMessageBox {
      font-size: 1.1rem;
    }

    .submit-button {
      justify-self: start;
      padding: 0.7rem 1.3rem 0.9rem;
      font-size: 1.2rem;
      background-color: colors(dark-light-blue);
      border-radius: 1000rem;
      font-weight: bold;
      @include bounceOnHover;
    }
  }
}
