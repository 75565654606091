@import "helpers.module";

.ProductItemImageView {
  padding-top: 56.25%;

  background-color: rgb(172, 190, 207);
  overflow: hidden;
  position: relative;

  > .image-holder {
    position: absolute;
    @include setProps(top bottom left right, 0);

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > .NoImageAvailableView {
      $color: rgb(123, 149, 173);

      width: 100%;
      height: 100%;

      display: grid;
      align-content: center;

      position: relative;

      > .content {
        > .svg-icon-holder {
          @include setProps(margin-left margin-right, 37%);
          > svg {
            fill: $color;
            height: 100%;
            width: 100%;
          }
        }

        > .text {
          margin-top: 0.2rem;
          color: $color;
          font-weight: bold;
          font-size: 1rem;
          text-align: center;
        }
      }
    }
  }
}
