@import "helpers.module";

.ContactUs {
  @media (max-width: 520px) {
    --HeadedUpPageContainer-bottom-margin: 6rem;
  }
  > .content {
    > .body-content-holder {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: start;
      gap: 4rem;

      @mixin respondToNarrowDesktopSnapPoint {
        @media (max-width: 920px) {
          @content;
        }
      }

      @include respondToNarrowDesktopSnapPoint {
        grid-template-columns: 3fr 1fr;
        gap: 2.5rem;
      }

      @include respondToNarrowWidth {
        grid-template-columns: initial;
      }

      > .text-fields {
        display: grid;
        gap: 2rem;

        > .ErrorMessageBox {
          margin-left: 1rem;
          &.positive {
            --highlight-color: #{colors(grayish-blue-text)};
          }
        }

        > .submit-button {
          $font-size: 1rem;
          $top-and-bottom-padding: 1rem;
          $height: ($top-and-bottom-padding * 2) + $font-size;

          background-color: colors(dark-light-blue);
          text-transform: uppercase;
          font-weight: bold;

          font-size: $font-size;
          justify-self: end;
          height: $height;
          @include setProps(padding-left padding-right, 1.6rem);
          border-radius: $height / 2;

          @include bounceOnHover;
        }
      }

      > .contact-methods-holder {
        margin-top: 2rem;
        display: grid;
        justify-items: start;
        gap: 2rem;

        @include respondToNarrowWidth {
          display: none;
        }
        > .contact-method {
          display: grid;
          gap: 0.8rem;
          justify-items: start;

          > .title {
            @include applyBlueThemeTextGradient;
            font-weight: bold;
            font-size: 1.7rem;
            @include respondToNarrowDesktopSnapPoint {
              font-size: 1.4rem;
            }
          }

          > .info {
            font-size: 1.2rem;
            color: colors(grayish-blue-text);
          }
        }
      }
    }
  }
}
