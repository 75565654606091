@import "helpers.module";

.ProductItemImageSelector {
  max-width: 30rem;
  display: grid;
  gap: 1rem;

  $image-view-padding: 0.7rem;

  > .CustomInput {
    .ProductItemImageView {
      border-radius: 0.4rem;
    }
    > .input-box {
      padding: $image-view-padding;
    }
  }

  > .bottom-image-controls {
    margin-left: $image-view-padding;
    display: grid;
    justify-content: space-between;
    grid-auto-flow: column;
    align-items: center;

    @media (max-width: 410px) {
      margin-left: initial;
      justify-content: initial;
      justify-items: center;
      grid-auto-flow: row;
      gap: 1.5rem;
    }

    .EditImageButtons {
      display: grid;
      grid-auto-flow: column;
      gap: 1rem;

      > * {
        border-radius: 100000px;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        font-weight: bold;
        background-color: colors(dark-light-blue);
        @include bounceOnHover;
      }
    }

    .FitOrFillChooser {
      display: grid;
      grid-auto-flow: column;

      gap: 1.2rem;

      .RadioButtonOption {
        font-size: 17px;

        input[type="radio"] + label {
          cursor: pointer;
        }

        input[type="radio"] {
          position: absolute;
          top: auto;
          overflow: hidden;
          clip: rect(1px, 1px, 1px, 1px);
          width: 1px;
          height: 1px;
          white-space: nowrap;
        }

        input[type="radio"] + label::before {
          content: "";
          display: block;
          float: left;
          border-radius: 100000000rem;
          @include setProps(height width, 1em);
          margin-top: 0.05em;
          margin-right: 0.5em;
          border: 0.1em solid rgba(white, 0.75);
          box-sizing: border-box;
          transition: all 0.1s ease-out;
        }

        input[type="radio"]:checked + label::before {
          box-shadow: inset 0 0 0 0.15em rgba(colors(light-dark-blue), 0.95);
          background-color: colors(light-blue);
        }
      }
    }
  }
}
