@import "helpers.module";

.CustomTextField {
  > input,
  textarea {
    &::placeholder {
      color: var(--input-placeholder-color);
    }
  }

  > textarea {
    height: 9rem;
  }
}
