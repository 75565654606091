$offset: 187;
$duration: 1.4s;

.LoadingIndicator {
  > svg {
    animation: rotator $duration linear infinite;
    stroke: white;

    @keyframes rotator {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(270deg);
      }
    }

    .path {
      stroke-dasharray: $offset;
      stroke-dashoffset: 0;
      transform-origin: center;

      animation: dash $duration ease-in-out infinite;
    }

    @keyframes dash {
      0% {
        stroke-dashoffset: $offset;
      }
      50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
      }
      100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
      }
    }
  }
}
