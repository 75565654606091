@import "helpers.module";

.CustomInput {
  > select {
    word-break: break-word;
    // for microsoft edge on windows
    word-wrap: break-word;

    line-height: 1.5;
    width: 100%;
    white-space: pre-wrap;

    &:invalid {
      color: var(--input-placeholder-color);
    }

    > option {
      color: initial;
    }
  }

  > textarea {
    height: 9rem;
  }
}
